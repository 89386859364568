.App {
  text-align: left;
  height: 100%;
  background-color: #f8f8f8;
}

html {
  height: 100vh;
  background-color: #f8f8f8;
}
.buttonvi {
}

.mobile-select
{
  white-space: break-spaces;
}

.posfix {
  display: flex;
  position: fixed;
  margin-left: 12%;
  justify-content: space-around;
  bottom: 2rem;
  border-color: #007d8a;
  color: teal;
  border-radius: 12px;
  padding: 10px 4px;
}

.posfixb.hidden {
  opacity: 0;
}

.posfixb {
  opacity: 1;
  position: fixed;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 2rem;
  background: #007d8a;
  color: white;
  z-index: 1;
  padding: 10px 100px;
}
.company-link {
  margin-left: 10px;
  vertical-align: sub;
  font-size: 22px;
}

icon {
  justify-self: end;
  justify-content: end;
  justify-items: end;
  align-items: end;
  align-self: flex-end;
  align-content: end;
}
.map-pointer-icon {
  font-size: 24px;
  vertical-align: unset;
}

.short-divider {
  width: 165px;
  background-color: #007d8a;
  margin: 0 auto;
  border-bottom-width: initial;
}

.header-container {
  opacity: 0;
  background-position: center;
  min-height: 200px;
  width: 100%;
  background-image: url("img/header/test-header-mobile.jpg");
}

.header-container.visible {
  opacity: 1;
}
.header-gesundheit {
  background-image: url("img/header/STmedical-mobile.png");
  background-position: bottom center;
}
.header-it {
  background-position: right top;
  background-image: url("img/header/STtechheader-1-mobile.png");
}

.address-link {
  margin: 0 auto;
}

.detail-container h1,
.detail-container h2 {
  text-align: center !important;
}

.logo-container {
  margin: 15px auto;
}

.logo-container img {
  padding: 0px;
  margin: 0 auto;
}

.arrow-back:hover {
  border: none;
  background-color: transparent;
}

.arrow-back > svg {
  height: 35px;
  width: 35px;
}

.arrow-back > span {
  font-size: 16px;
  margin-left: 8px;
  transition: margin 0.5s ease-in-out;
}

.arrow-back:hover > span {
  margin-left: 14px;
  transition: margin 0.5s ease-in-out;
}

@media screen and (min-width: 600px) {
  .posfix {
    display: none;
  }

  .job-detail-container {
    background-color: #f8f8f8;
    padding: 20px;
  }

  .header-container {
    height: 600px;
    min-height: auto;
    background-image: url("img/header/test-header.jpg");
    background-position: center center;
  }
  .header-it {
    background: url("img/header/STtechheader-1-gros.png") no-repeat;
    background-position: right top;
  }

  .header-gesundheit {
    background-image: url("img/header/STmedical-header.png");
    background-position: bottom center;
  }
}

@media screen and (min-width: 768px) {
  .job-detail-container {
    margin-top: -100px;
    width: 80%;
  }
  .header-container {
    height: 600px;
    min-height: auto;
  }

  .header-gesundheit {
    background: url("img/header/STmedical-header.png") no-repeat;
    background-position: bottom center;
  }
  .header-it {
    background: url("img/header/STtechheader-1-gros.png") no-repeat;
    background-position: center top;
  }
}
